<template>
<div class="sub_cont pricing bg_gray">
 <div class="box_top_bg">
    <div class="sub_title_p wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s" >
      <b>역물류에 최적화된 요금 구성</b>으로 <br>
      클라이언트의 물류를 이행합니다.
    </div>
    <div class="box_icon">
      <ul>
        <li class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s" >
          <div class="box_img"><img src="../../assets/img/p001.png"/></div>
          <div class="txt_area">데이터 기반으로 투명한 요금제
            서비스 등급에 따라 최적화된 요금을 
            데이터에 기반해서 제공합니다.
          </div>
        </li>
        <li class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.4s" >
          <div class="box_img"><img src="../../assets/img/p002.png"/></div>
          <div class="txt_area">단순한 서비스 요금구조 통으로 通하는 
            SaaS가격정책을 제시합니다. 
          </div>
        </li>
        <li class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.6s" >
          <div class="box_img"><img src="../../assets/img/p003.png"/></div>
          <div class="txt_area">리퍼브 공정 국내 최저가 적용
                체험형 물류서비스 연계, 상품 무한 재생
                국내 최저 비용으로 경험해보세요.
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="cont">
  <h2 class="mgt0">기본 요금제</h2>
  <div class="table_price pc wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset='30'>
  <table  border="0" cellspacing="0" cellpadding="0"> 
    <colgroup>
      <col width="20%" />
      <col width="26%" />
      <col width="26%" />      
      <col width="26%" />
    </colgroup>
    <thead>
    <tr>
      <th>구분</th>
      <th v-for="item in commonSvcMainListResponseList">{{ item.svcNm }}</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td>{{ commonSvcMainListResponseList[0].commonSvcDtlResponseList[0].svcDtlNm }}<span class="txt_s">[{{ commonSvcMainListResponseList[0].commonSvcDtlResponseList[0].svcDtlBillCdNm }}]</span></td>
      <td v-for="i in [0,1,2]">
        <span class="fc_b">{{ commonSvcMainListResponseList[i].commonSvcDtlResponseList[0].svcDtlBillCost | comma }}</span> 원
      </td>
    </tr>
    <tr>
      <td>{{ commonSvcMainListResponseList[0].commonSvcDtlResponseList[1].svcDtlNm }}<span class="txt_s">[{{ commonSvcMainListResponseList[0].commonSvcDtlResponseList[1].svcDtlBillCdNm }}]</span></td>
      <td v-for="i in [0,1,2]">
        <span>{{ commonSvcMainListResponseList[i].commonSvcDtlResponseList[1].svcDtlBillCost | comma}}</span> 원
      </td>
    </tr>
    <tr>
      <td>{{ commonSvcMainListResponseList[0].commonSvcDtlResponseList[2].svcDtlNm }}<span class="txt_s">[{{ commonSvcMainListResponseList[0].commonSvcDtlResponseList[2].svcDtlBillCdNm }}]</span></td>
      <td v-for="i in [0,1,2]">
        <span>{{ commonSvcMainListResponseList[i].commonSvcDtlResponseList[2].svcDtlBillCost | comma}}</span> 원
      </td>
    </tr>
    <tr>
      <td>{{ commonSvcMainListResponseList[0].commonSvcDtlResponseList[3].svcDtlNm }}<span class="txt_s">[{{ commonSvcMainListResponseList[0].commonSvcDtlResponseList[3].svcDtlBillCdNm }}]</span></td>
      <td v-for="i in [0,1,2]">
        <span>{{ commonSvcMainListResponseList[i].commonSvcDtlResponseList[3].svcDtlBillCost | comma}}</span> 원
      </td>
    </tr>
    </tbody>
  </table>
  </div>
  <h2>추가 옵션</h2>

    <div class="box_check_btn wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset='30'>
      <template v-for="(item, i) in commonSvcAddListResponseList">
        <div v-for="childItem in item.commonSvcDtlResponseList" class="btn_check">
          <img :src="item.img">
          <div class="service_name">{{ item.customName }}</div>
          <div class="price_r">{{childItem.svcDtlBillCdNm}}
            <span class="fc_b">{{ childItem.svcDtlBillCost | comma}}</span>원
          </div>
        </div>
      </template>
    </div>
      <div v-if="!loginUserData || (!!loginUserData && loginUserData.authCd === Constant.efs.auth.TEMPORARY_USER)"
          @click="onClickServiceReq" class="box_btn">
       <div class="btn orange"><a>서비스 신청 바로가기</a></div>
      </div>
  </div>
</div>
</template>



<script>
import {WOW} from 'wowjs';
import PopupForLanding from '../components/PopupForLanding.vue'
import ApiUtil from '@/api/api.util'
import ApiConfig from '@/api/api.config'
import Constant from '@/common/constant'
export default {
  name: 'Pricing',
  components : {
    PopupForLanding,
  },
  computed : {
    loginUserData: function () {
      return this.$store.getters['loginUserData']
    },
  },
  created () {
    const vm = this
    vm.getSvcList()
  },
  mounted() {
    new WOW().init();
  },
  methods : {
    getSvcList : function() {
      const vm = this
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/svc/pricing`)
      .then(response => {
        if (response.data === undefined || response.data.resultStatus === undefined) {
          alert('데이터가 정상적으로 처리되지 않았습니다.')
          return false
        }
        if (response.data.resultStatus.messageCode !== '2000') {
          alert(response.data.resultStatus.messageText)
          return false
        }
        vm.commonSvcAddListResponseList = response.data.resultData.commonSvcAddListResponseList
        vm.commonSvcAddListResponseList.forEach((item, i)=>{
          switch (item.svcNo){
            case vm.constant.svcNo.sub_3D : {
              item.customName = '3D VIEW 적용'
              item.img = require('../../assets/img/p004.png')
              item.select = false
              break
            }
            case vm.constant.svcNo.sub_MEDIA : {
              item.customName = '출고/회수 미디어 저장'
              item.img = require('../../assets/img/p005.png')
              item.select = false
              break
            }
          }
        })
        vm.commonSvcMainListResponseList = response.data.resultData.commonSvcMainListResponseList
      })
    },
    onClickServiceReq : function (){
      const vm = this
      if(!vm.loginUserData?.authCd){
        vm.existLoginInfo = false
        this.$store.dispatch('SET_IS_SHOW_LOGIN_POPUP', true)
      }else {
        vm.existLoginInfo = true
        vm.$router.push({name:'RequestService'})
        this.$store.dispatch('SET_IS_SHOW_LOGIN_POPUP', false)
      }
    }
  },
  data() {
    const tempData = { commonSvcDtlResponseList: [
        { svcDtlNm : '', svcDtlBillCost : 0},
        { svcDtlNm : '', svcDtlBillCost : 0},
        { svcDtlNm : '', svcDtlBillCost : 0},
        { svcDtlNm : '', svcDtlBillCost : 0},
      ] }
    return {
      Constant,
      commonSvcMainListResponseList : [
        tempData,
        tempData,
        tempData,
      ],
      commonSvcAddListResponseList : [],
      existLoginInfo : true,
      constant : {
        svcNo : {
          sub_MEDIA : "202208010004",
          sub_3D : "202208010005"
        }
      }
    }
  },
}
</script>

<style scoped>

</style>
